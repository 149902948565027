// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement("style");
    msViewportStyle.appendChild(document.createTextNode("@-ms-viewport{width:auto!important}"));
    document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
}

// Mobile menutoggle
$('.menutoggle').click(function() {
    $('ul.mainnav').slideToggle();
});

// Mobile sidebar submenu toggle
$('#select-subpage').click(function() {
    $('.submenu ul.nav').slideToggle();
    $(this).find('.fa').toggleClass('fa-angle-right fa-angle-up');
});

$(document).ready(function(){
    // FitVids
    if ($.isFunction($.fn.fitVids)) {
        $('.fitvids').fitVids();
    }
})

/**
 * Introtext
 * Split introtext after first paragraph for mobile devices
 */

var introTextSelector = $('#intro-text');
var introTextArray = $('#intro-text').html().split('</p>');
introTextSelector.html(introTextArray[0]);
introTextSelector.find('p:eq(0)')
    .after('<div class="collapsed-text"></div>')
    .after('<a id="show-text" class="visible-xs visible-sm">Lees meer &raquo;</a>');
introTextSelector.find('.collapsed-text').html(introTextArray.splice(1));

// Readmore button
introTextSelector.find('#show-text').click(function() {
    $(this).remove();
    introTextSelector.find('.collapsed-text').slideDown();
});
